/* Ensure the app container takes full viewport */
.app-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

/* Background styles */
#large-header {
    position: fixed; /* Fixed to ensure it stays in the background */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; /* Ensure it is behind other content */
}

/* Main content styles */
.content {
    position: relative;
    z-index: 1; /* Ensure content is above the background */
    padding: 20px;
}
